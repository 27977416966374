<template>
  <v-container class="d-flex flex-column">
    <v-card v-if="showForm" flat class="pa-3">
      <CategoryTitle :category="category" />
      <div class="mt-4">
        <v-form
          v-on:submit.prevent
          ref="form"
          v-model="isValid"
          :lazy-validation="lazy"
        >
          <v-text-field
            v-model="password"
            :label="$t('profile.newPassword') + ' *'"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            :hint="`${$t('profile.passwordInfo')}`"
            required
            outlined
            dense
          />

          <v-text-field
            v-model="passwordConfirm"
            :label="$t('profile.passwordConfirm') + ' *'"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            :hint="`${$t('profile.passwordInfo')}`"
            required
            outlined
            dense
          />
          <v-card-actions class="pa-0">
            <v-btn
              @click="handleResetPasswordConfirm"
              class="reset-button"
              color="primary"
              large
              :block="$vuetify.breakpoint.xs"
              min-width="200"
              height="40"
              depressed
              :loading="loading"
              :disabled="disableFormSubmission"
              >{{ $t("login.submitResetPassword") }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </div>

      <div class="mt-2">
        <ResponseMessage :response="response" />
      </div>
    </v-card>
  </v-container>
</template>
<style lang="scss"></style>
<script>
import ResponseMessage from "@/components/ResponseMessage.vue";
import CategoryTitle from "@/components/category/CategoryTitle";

import category from "~/mixins/category";
import customService from "@/service/customService";
// import RegistrationService from "~/service/userService";
import userService from "~/service/userService";

import { requiredValue } from "~/validator/validationRules";
import {
  minLength,
  minCapitalCharacter,
  minSymbolCharacter
} from "~/validator/validationRules";

export default {
  components: { ResponseMessage, CategoryTitle },
  name: "ResetPasswordConfirm",
  mixins: [category],
  data() {
    return {
      token: "",
      isValid: false,
      loading: false,
      lazy: true,
      response: {},
      showForm: true,
      password: null,
      passwordConfirm: null,
      showPassword: false,
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(
          8,
          "La password deve essere lunga almeno 8 caratteri e può contenere solo lettere, numeri o i caratteri @#$%^&+*!="
        ),
        minCapitalCharacter(),
        minSymbolCharacter()
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ]
    };
  },
  props: {
    message: { type: Object, default: () => {} },
    login: { type: String, default: undefined },
    queryToken: { type: String, default: undefined }
  },
  computed: {
    disableFormSubmission() {
      return !(
        this.password &&
        this.passwordConfirm &&
        this.password == this.passwordConfirm
      );
    }
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    async checkToken(token) {
      let _this = this;
      try {
        _this.loading = true;
        let res = await userService.resetPasswordTokenCheck(
          undefined,
          token || _this.token,
          _this.login
        );

        if (res.response?.status == 0) {
          if (token) {
            this.token = token;
          }
          _this.response = res;
        } else {
          _this.response = res;
        }
      } catch (err) {
        if (err.errors) _this.response = err;
      } finally {
        this.loading = false;
      }
    },
    async handleResetPasswordConfirm(e) {
      let _this = this;
      e.preventDefault();
      _this.response = {};
      if (_this.isValid) {
        _this.loading = true;
        let res = null;
        try {
          res = await customService.resetPassword(
            undefined,
            _this.token,
            _this.password,
            _this.login
          );
          if (res.response?.status == 0) {
            _this.$router.push({ name: "ResetPasswordCompleted" });
          } else {
            _this.response = res.response;
          }
        } catch (err) {
          if (err.errors) {
            _this.response = err;
          } else {
            _this.response = err.response.data.response;
          }
        } finally {
          _this.loading = false;
        }
      }
    },
    resetPage() {
      this.email = "";
      this.response = {};
      this.showForm = true;
    }
  },

  mounted() {
    console.log("mounted psw confirm : ", this.$route, this.$router);
    console.log("mounted psw confirm : ", this.login, this.queryToken);
    if (this.queryToken && this.login) {
      this.checkToken(this.queryToken);
    }
  }
};
</script>
